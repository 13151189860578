import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Button,
  Chip,
  Fade,
  Grid,
  TextField
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "../../app/helpers";
import RestrictionPicker from "../../components/Pickers/restriction-picker.component";
import ScheduleCalendar from "../../components/Schedule/ScheduleCalendar/schedule-calendar.component";
import { InfoIconWithTooltip } from "../../components/Title/InfoIcon";
import { getScheduleColleagues } from "../../utils/axios-requests";
import { BookingScheduleInterface, RestrictionZone } from "../Booking-Form/typings/booking.types";
import { TeamMember } from "../Booking-Form/typings/team-member";
import { PricingModel } from "../Login/typings/login.types";
import {
  getOptionLabel,
  handleMultiDeleteBookings,
  updateSelectedAppointment
} from "./schedule.functions";
import { RootState } from "../../app/rootReducer";
import { useRemoteInactivateBookings } from "../../hooks/Remote/Booking/useRemoteInactivateBookings";
import { useRemoteFetchPlaceSchedule } from "../../hooks/Remote/Schedule/useRemoteFetchPlaceSchedule";
import { useRemoteFetchPlaceZoneSchedule } from "../../hooks/Remote/Schedule/useRemoteFetchPlaceZoneSchedule";
import { useRemoteFetchNoPlaceZoneBookingSchedule } from "../../hooks/Remote/Schedule/useRemoteFetchNoPlaceZoneBookingSchedule";
import { BookingType } from "../Booking-Form/typings/booking-inputs";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

type P = {
  isQuickAutomatedPlaceBooking: boolean;
  setIsQuickAutomatedPlaceBooking: (q: boolean) => void;
};

/**
 * @description The schedule page renders the whole user dropdown and year selection. It controls the workplace and zone schedule view.
 */
const Schedule: React.FC<P> = ({
  isQuickAutomatedPlaceBooking,
  setIsQuickAutomatedPlaceBooking
}) => {
  const { t } = useTranslation();
  const {
    userInformation: { sub, firstName, surname, pricingModels }
  } = useSelector((state: RootState) => state.login);

  const [schedules, setSchedules] = useState<BookingScheduleInterface[]>([]);
  const [calendarDate, setCalendarDate] = useState<{
    firstDay: string | undefined;
    lastDay: string | undefined;
  }>({ firstDay: undefined, lastDay: undefined });

  const [deletedBookings, setDeletedBookings] = useState<boolean>(false);
  const [colleagues, setColleagues] = useState<TeamMember[]>([
    {
      firstName: firstName,
      surname: surname,
      userId: sub
    }
  ]);

  const [selectedAppointments, setSelectedAppointments] = useState<number[]>([]);
  const [selectedColleagues, setSelectedColleagues] = useState<TeamMember[]>([
    {
      firstName: firstName,
      surname: surname,
      userId: sub
    }
  ]);

  const [selected, setSelected] = useState<RestrictionZone>({
    company: { id: 0, name: "" },
    object: { id: 0, zoneBookingObject: "", type: "", name: "" }
  } as RestrictionZone);

  const userIds = selectedColleagues
    ? selectedColleagues.map((user: { userId: string }) => user.userId)
    : [sub];

  const {
    data: placeScheduleData,
    isFetching: placeScheduleDataIsFetching,
    refetch: refetchPlaceSchedule
  } = useRemoteFetchPlaceSchedule({
    userIds: userIds,
    startDate: calendarDate.firstDay,
    endDate: calendarDate.lastDay
  });
  const {
    data: placeZoneScheduleData,
    isFetching: placeZoneScheduleDataIsFetching,
    refetch: refetchPlaceZoneSchedule
  } = useRemoteFetchPlaceZoneSchedule({
    userIds: [sub],
    startDate: calendarDate.firstDay,
    endDate: calendarDate.lastDay
  });
  const {
    data: placeZoneScheduleDataForEnterprise,
    isFetching: placeZoneScheduleDataForEnterpriseIsFetching,
    refetch: refetchPlaceZoneScheduleForEnterprise
  } = useRemoteFetchPlaceZoneSchedule({
    userIds: [sub],
    startDate: calendarDate.firstDay,
    endDate: calendarDate.lastDay,
    zoneBookingObject: selected?.object.zoneBookingObject,
    client: selected?.company.id || undefined
  });
  const {
    data: noPlaceZoneBookingScheduleData,
    isFetching: noPlaceZoneBookingScheduleDataIsFetching,
    refetch: refetchNoPlaceZoneBookingSchedule
  } = useRemoteFetchNoPlaceZoneBookingSchedule({
    userIds: userIds,
    startDate: calendarDate.firstDay,
    endDate: calendarDate.lastDay
  });

  const refetchAllSchedule = () => {
    refetchPlaceSchedule();
    refetchPlaceZoneSchedule();
    refetchNoPlaceZoneBookingSchedule();
    refetchPlaceZoneScheduleForEnterprise();
  };

  useEffect(() => {
    getScheduleColleagues()
      .then((response: { data: any[] }) => {
        const selection = [
          {
            userBookedById: sub,
            firstName: firstName,
            surname: surname,
            userId: sub
          }
        ].concat(response.data);

        setColleagues(selection);
      })
      .catch(err => console.warn(err));
  }, [firstName, sub, surname]);

  useEffect(() => {
    if (
      placeScheduleData &&
      placeZoneScheduleData &&
      placeZoneScheduleDataForEnterprise &&
      noPlaceZoneBookingScheduleData
    ) {
      setSchedules([
        ...placeScheduleData,
        ...placeZoneScheduleData,
        ...placeZoneScheduleDataForEnterprise,
        ...noPlaceZoneBookingScheduleData
      ]);
    }
  }, [
    placeScheduleDataIsFetching,
    placeZoneScheduleDataIsFetching,
    placeZoneScheduleDataForEnterpriseIsFetching,
    noPlaceZoneBookingScheduleDataIsFetching
  ]);

  const updateAppointmentData = (appointment: number) =>
    updateSelectedAppointment(appointment, selectedAppointments, setSelectedAppointments);

  const { mutate: inactivateBookings, status: inactivateBookingsStatus } =
    useRemoteInactivateBookings();

  useEffect(() => {
    if (inactivateBookingsStatus === "error") {
      setSelectedAppointments([]);
    }
    if (inactivateBookingsStatus === "success") {
      setDeletedBookings(false);
      setSelectedAppointments([]);
    }
  }, [inactivateBookingsStatus]);

  useEffect(() => {
    if (isQuickAutomatedPlaceBooking) {
      setIsQuickAutomatedPlaceBooking(false);
      refetchAllSchedule();
    }
  }, [isQuickAutomatedPlaceBooking]);

  return (
    <>
      <Fade in={true}>
        <Grid
          container
          direction={"row"}
          justifyContent={"space-between"}
          wrap={"wrap"}
          alignItems={"center"}
        >
          {selectedAppointments.length > 0 && (
            <>
              <Button
                onClick={() =>
                  handleMultiDeleteBookings({
                    selectedSchedules: schedules
                      .filter(sch => selectedAppointments.includes(sch.id))
                      .map(s => ({ id: s.id, bookingType: s.bookingType as BookingType })),
                    setDeletedBookings,
                    inactivateBookings
                  })
                }
                sx={{ background: "rgb(211, 47, 47)" }}
                color="error"
                data-testid="schedule-multi-delete-btn"
              >
                {t("DeleteBookings", { amount: selectedAppointments.length })}
              </Button>
            </>
          )}

          {colleagues && colleagues.length > 1 && (
            <Autocomplete
              data-testid="schedule-team-selection"
              multiple
              id="tags-outlined4"
              options={colleagues}
              getOptionLabel={getOptionLabel}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.userId}>
                    {getOptionLabel(option)}
                  </li>
                );
              }}
              renderTags={(tagValue, getTagProps) => {
                return tagValue.map((option, index) => (
                  <Chip
                    {...getTagProps({ index })}
                    label={getOptionLabel(option)}
                    key={option.userId}
                  />
                ));
              }}
              style={{ minWidth: "300px", width: "100%", margin: "10px 0 20px 0" }}
              onChange={(event: unknown, values: unknown) => {
                setSelectedColleagues(values as TeamMember[]);
              }}
              isOptionEqualToValue={(option, value) => option.userId === value.userId}
              value={selectedColleagues}
              renderInput={(params: AutocompleteRenderInputParams) => (
                <TextField
                  {...params}
                  variant="standard"
                  fullWidth
                  placeholder={t("Choose your colleagues")}
                />
              )}
            />
          )}
        </Grid>
      </Fade>
      <Fade
        mountOnEnter
        unmountOnExit
        in={selectedColleagues && pricingModels.includes(PricingModel.ENTERPRISE)}
      >
        <Grid container direction={"row"} alignItems={"flex-end"} justifyContent={"flex-start"}>
          <RestrictionPicker
            userId={selectedColleagues[0]?.userId || sub}
            mode={false}
            selection={selected}
            setSelection={setSelected}
          />
          <InfoIconWithTooltip
            tooltipText={t("Choose your company units that you have booked a zone for")}
          />
        </Grid>
      </Fade>
      <ScheduleCalendar
        data-testid="schedule"
        monthView={true}
        deletingBookings={deletedBookings}
        setDeletingBookings={(d: boolean) => setDeletedBookings(d)}
        setSelectedAppointment={updateAppointmentData}
        selectedAppointments={selectedAppointments}
        selectedColleagues={selectedColleagues}
        selectedRestriction={selected}
        users={selectedColleagues}
        schedules={schedules}
        setCalendarDate={setCalendarDate}
        refetchAllSchedule={refetchAllSchedule}
      />
    </>
  );
};

export default Schedule;
