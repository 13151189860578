import z, { Infer } from "myzod";
import { locationScheduleSchema } from "./location-schedule.entity";
import { permissionSchema } from "./permission.entity";
import { inventorySchema } from "./shared/inventory.entity";
import { disabledScheduleSchema } from "./shared/timeframe";

export const locationInventorySchema = inventorySchema.and(
  z.object({
    address1: z.string().min(1, "Address is required."),
    address2: z.string().optional().nullable(),
    address3: z.string().optional().nullable(),
    postalCode: z.string().or(z.undefined()).nullable(),
    city: z.string().min(1, "City is required.").nullable(),
    state: z.string().optional().nullable(),
    country: z.string().min(1, "Country is required."),
    approval: z.boolean().or(z.undefined()),
    picturePath: z.string().nullable(),
    maxLevels: z
      .number()
      .min(0, "Location must have at least one floor.")
      .max(250, "Location cannot have more than 250 floors.")
      .onTypeError("Please provide the maximum amount of floors this location can have."),
    color: z.string(),
    timezone: z
      .string()
      .min(1, "Location timezone is required.")
      .onTypeError("Select a timezone from the list."),
    allowChangeDeskCost: z.boolean(),
    allowForBooking: z.boolean(),
    bookingAllowedAt: z.string().nullable(),
    isMobileWorking: z.boolean(),

    permissions: z.array(permissionSchema).or(z.undefined()),
    isUserAccessAllowed: z.boolean().or(z.undefined()),
    checkinPeriod: z.number(),
    checkinRadius: z.number(),
    schedule: z.array(locationScheduleSchema).or(z.undefined()),
    disabledSchedule: z.array(disabledScheduleSchema).or(z.undefined()),
    exivoSiteId: z.string().or(z.null()),
    accessWithGeoLocation: z.boolean()
  })
);
export type LocationInventory = Infer<typeof locationInventorySchema>;

export const locationResponseSchema = z.array(locationInventorySchema);
