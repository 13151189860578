import { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "../../../../../app/helpers";
import { RootState } from "../../../../../app/rootReducer";
import {
  RemoteCompany,
  useRemoteFetchCompanies
} from "../../../../../hooks/Remote/Companies/useRemoteFetchCompanies";
import {
  CompanyUnits,
  useRemoteFetchCompanyUnits
} from "../../../../../hooks/Remote/CompanyUnits/useRemoteFetchCompanyUnits";
import { useRemoteFetchUserInfoForAllUsers } from "../../../../../hooks/Remote/User/UserInfo/useRemoteFetchUserInfoForAllUsers";
import { PermissionSelect } from "../InfoSidebar/PermissionSelect/PermissionSelect";
import { PermissionChangeProps, PermsType } from "./PermissionChange.partial";
import { Stakeholder } from "../../../../../features/FloorManager/typings/permission.entity";
import { Colleague } from "../../../../../features/Connections/types/Colleague.type";
import {
  RemoteCompanyPerm,
  RemoteCompanyUnitsPerm,
  RemoteUsersPerm
} from "../../../Domain/Types/FloorPlan/Permission.type";
import { WeekdayNames } from "../../../Domain/Types/FloorPlan/WeekdayNames.type";
import { NewPerms } from "../../Views/CreateFloorPlanView/Tools/useFloorplan/useFloorplan";

type PermissionSelectGroupProps = {
  initialPermCompanies: NewPerms["companies"];
  initialPermCompanyUnits: NewPerms["costCenterUnits"];
  initialPermUsers: NewPerms["users"];
  type: "place" | "zone";
  handlePermChange: ({ type, selected, actions }: PermissionChangeProps) => void;
  zoneUpdatePerms?: (
    companies: RemoteCompanyPerm[],
    units: RemoteCompanyUnitsPerm[],
    users: RemoteUsersPerm[]
  ) => void;
  placeUpdatePerms?: (
    companies: RemoteCompanyPerm[],
    units: RemoteCompanyUnitsPerm[],
    users: RemoteUsersPerm[]
  ) => void;
};

export function PermissionSelectGroup({
  initialPermCompanies,
  initialPermCompanyUnits,
  initialPermUsers,
  type,
  handlePermChange,
  zoneUpdatePerms,
  placeUpdatePerms
}: PermissionSelectGroupProps) {
  const { t } = useTranslation();

  const { bookingsOnSaturdaysAllowed, bookingsOnSundaysAllowed } = useSelector(
    (state: RootState) => state.login.userInformation.company.meta
  );

  const { data: remoteCompanies, isFetching: isRemoteCompaniesFetching } =
    useRemoteFetchCompanies();
  const { data: remoteCompanyUnits, isFetching: isRemoteCompanyUnitsFetching } =
    useRemoteFetchCompanyUnits(Stakeholder.CostCenterUnit);
  const { data: remoteUsers, isFetching: isRemoteUsersFetching } =
    useRemoteFetchUserInfoForAllUsers();

  const [selectedCompanies, setSelectedCompanies] = useState<RemoteCompanyPerm[]>([]);
  const [selectedCompanyUnits, setSelectedCompanyUnits] = useState<RemoteCompanyUnitsPerm[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<RemoteUsersPerm[]>([]);

  const defaultOptionalPerms = {
    enforceUsage: false,
    activeWeekdays: [
      WeekdayNames.MONDAY,
      WeekdayNames.TUESDAY,
      WeekdayNames.WEDNESDAY,
      WeekdayNames.THURSDAY,
      WeekdayNames.FRIDAY
    ],
    hoursInAdvance: 0
  };
  if (bookingsOnSaturdaysAllowed) defaultOptionalPerms.activeWeekdays.push(WeekdayNames.SATURDAY);
  if (bookingsOnSundaysAllowed) defaultOptionalPerms.activeWeekdays.push(WeekdayNames.SUNDAY);

  useEffect(() => {
    if (remoteCompanies && remoteCompanyUnits && remoteUsers) {
      const { companies, companyUnits, users } = initSelectedUnits({
        remoteCompanies,
        remoteCompanyUnits,
        remoteUsers,
        initialPermCompanies,
        initialPermCompanyUnits,
        initialPermUsers
      });

      setSelectedCompanies(companies);
      setSelectedCompanyUnits(companyUnits);
      setSelectedUsers(users);
    }
  }, [remoteCompanies, remoteCompanyUnits, remoteUsers]);

  return (
    <>
      {/* permission of company */}
      <PermissionSelect
        type={type}
        label={PermsType.Companies}
        disabledRemoteOpt={isRemoteCompaniesFetching}
        initialValue={selectedCompanies}
        entries={remoteCompanies?.map(r => ({ ...r, ...defaultOptionalPerms })) ?? []}
        handleChange={(e: any, value: RemoteCompanyPerm[]) => {
          handlePermChange({
            type: PermsType.Companies,
            selected: { companies: value },
            actions: { setSelectedCompanies }
          });
        }}
        handleStricPermChange={(e: any, value: RemoteCompanyPerm) => {
          const i = selectedCompanies.findIndex(u => u.id === value.id);
          selectedCompanies[i].enforceUsage = !selectedCompanies[i].enforceUsage;
        }}
        handleActiveWkdayChange={(option: RemoteCompanyPerm, value: WeekdayNames[]) => {
          const i = selectedCompanies.findIndex(u => u.id === option.id);
          selectedCompanies[i].activeWeekdays = value;
        }}
        handleTimeSpanChange={(option: RemoteCompanyPerm, value: number) => {
          const i = selectedCompanies.findIndex(u => u.id === option.id);
          selectedCompanies[i].hoursInAdvance = value;
        }}
      />

      {/* permission of company units */}
      <PermissionSelect
        type={type}
        label={PermsType.CompanyUnits}
        disabledRemoteOpt={isRemoteCompanyUnitsFetching}
        initialValue={selectedCompanyUnits}
        entries={remoteCompanyUnits?.map(r => ({ ...r, ...defaultOptionalPerms })) ?? []}
        handleChange={(e: any, value: RemoteCompanyUnitsPerm[]) => {
          handlePermChange({
            type: PermsType.CompanyUnits,
            selected: { companyUnits: value },
            actions: { setSelectedCompanyUnits }
          });
        }}
        handleStricPermChange={(e: any, value: RemoteCompanyUnitsPerm) => {
          const i = selectedCompanyUnits.findIndex(u => u.id === value.id);
          selectedCompanyUnits[i].enforceUsage = !selectedCompanyUnits[i].enforceUsage;
        }}
        handleActiveWkdayChange={(option: RemoteCompanyUnitsPerm, value: WeekdayNames[]) => {
          const i = selectedCompanyUnits.findIndex(u => u.id === option.id);
          selectedCompanyUnits[i].activeWeekdays = value;
        }}
        handleTimeSpanChange={(option: RemoteCompanyUnitsPerm, value: number) => {
          const i = selectedCompanyUnits.findIndex(u => u.id === option.id);
          selectedCompanyUnits[i].hoursInAdvance = value;
        }}
      />

      {/* permission of user */}
      <PermissionSelect
        type={type}
        label={PermsType.Users}
        disabledRemoteOpt={isRemoteUsersFetching}
        initialValue={selectedUsers}
        entries={remoteUsers?.map(r => ({ ...r, ...defaultOptionalPerms })) ?? []}
        handleChange={(e: any, value: RemoteUsersPerm[]) => {
          handlePermChange({
            type: PermsType.Users,
            selected: { users: value },
            actions: { setSelectedUsers }
          });
        }}
        handleStricPermChange={(e: any, value: RemoteUsersPerm) => {
          const i = selectedUsers.findIndex(u => u.userId === value.userId);
          selectedUsers[i].enforceUsage = !selectedUsers[i].enforceUsage;
        }}
        handleActiveWkdayChange={(option: RemoteUsersPerm, value: WeekdayNames[]) => {
          const i = selectedUsers.findIndex(u => u.userId === option.userId);
          selectedUsers[i].activeWeekdays = value;
        }}
        handleTimeSpanChange={(option: RemoteUsersPerm, value: number) => {
          const i = selectedUsers.findIndex(u => u.userId === option.userId);
          selectedUsers[i].hoursInAdvance = value;
        }}
      />

      <Box sx={{ display: "flex", justifyContent: "center", paddingBottom: 0 }}>
        <Button
          data-testid={`${type}-perm-save`}
          onClick={() => {
            if (type === "zone")
              zoneUpdatePerms?.(selectedCompanies, selectedCompanyUnits, selectedUsers);

            if (type === "place")
              placeUpdatePerms?.(selectedCompanies, selectedCompanyUnits, selectedUsers);

            setSelectedCompanies([]);
            setSelectedCompanyUnits([]);
            setSelectedUsers([]);
          }}
          sx={{ width: "100%" }}
        >
          {t("Save")}
        </Button>
      </Box>
    </>
  );
}

type InitSelectedUnitsProps = {
  remoteCompanies: RemoteCompany[];
  remoteCompanyUnits: CompanyUnits[];
  remoteUsers: Colleague[];
  initialPermCompanies: NewPerms["companies"];
  initialPermCompanyUnits: NewPerms["costCenterUnits"];
  initialPermUsers: NewPerms["users"];
};
export function initSelectedUnits({
  remoteCompanies,
  remoteCompanyUnits,
  remoteUsers,
  initialPermCompanies,
  initialPermCompanyUnits,
  initialPermUsers
}: InitSelectedUnitsProps) {
  const basicWkdays = [
    WeekdayNames.MONDAY,
    WeekdayNames.TUESDAY,
    WeekdayNames.WEDNESDAY,
    WeekdayNames.THURSDAY,
    WeekdayNames.FRIDAY
  ];

  const companies = remoteCompanies
    .filter(c => initialPermCompanies.some(i => c.id === Number(i.id)))
    .map(company => ({
      ...company,
      enforceUsage:
        initialPermCompanies.find(c => Number(c.id) === company.id)?.enforceUsage ?? false,
      activeWeekdays:
        initialPermCompanies.find(c => Number(c.id) === company.id)?.activeWeekdays ?? basicWkdays,
      hoursInAdvance:
        initialPermCompanies.find(c => Number(c.id) === company.id)?.hoursInAdvance ?? 0
    }));

  const companyUnits = remoteCompanyUnits
    .filter(u => initialPermCompanyUnits.some(i => u.id === i.id))
    .map(unit => ({
      ...unit,
      enforceUsage: initialPermCompanyUnits.find(u => u.id === unit.id)?.enforceUsage ?? false,
      activeWeekdays:
        initialPermCompanyUnits.find(u => u.id === unit.id)?.activeWeekdays ?? basicWkdays,
      hoursInAdvance: initialPermCompanyUnits.find(u => u.id === unit.id)?.hoursInAdvance ?? 0
    }));

  const users = remoteUsers
    .filter(u => initialPermUsers.some(i => u.userId === i.id))
    .map(user => ({
      ...user,
      enforceUsage: initialPermUsers.find(u => u.id === user.userId)?.enforceUsage ?? false,
      activeWeekdays:
        initialPermUsers.find(u => u.id === user.userId)?.activeWeekdays ?? basicWkdays,
      hoursInAdvance: initialPermUsers.find(u => u.id === user.userId)?.hoursInAdvance ?? 0
    }));

  return { companies, companyUnits, users };
}
