import { Button, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import {
  getFloorPlanByWorkplaceInventoryId,
  getFloorPlanByZoneInventoryId
} from "../../utils/axios-requests";
import { EquipmentRow } from "./FilterableEquipmentList/typings/equipment-row.types";

interface P {
  params: EquipmentRow;
  setSelectedInventoryId: (scheduleId: number) => void;
  setFloorPlan: (r: any) => void;
  setOpen: (bool: boolean) => void;
}

const ShowAssignedFloor = ({ params, setSelectedInventoryId, setFloorPlan, setOpen }: P) => {
  const { t } = useTranslation();

  const { placeInventoryId, zoneInventoryId } = params;

  // fetch the floor plan by its inventory id and show floor plan modal
  const showFloorByInventoryId = (): void => {
    // when the item is place
    if (placeInventoryId && placeInventoryId !== null) {
      setSelectedInventoryId(placeInventoryId);
      getFloorPlanByWorkplaceInventoryId(placeInventoryId, {
        start: DateTime.now().toISO(),
        end: DateTime.now().plus({ minutes: 1 }).toISO()
      }).then(r => {
        setFloorPlan(r.data);
        setOpen(true);
      });
    }

    // when the item is zone
    if (zoneInventoryId && zoneInventoryId !== null) {
      setSelectedInventoryId(zoneInventoryId);
      getFloorPlanByZoneInventoryId(zoneInventoryId, {
        start: DateTime.now().toISO(),
        end: DateTime.now().plus({ minutes: 1 }).toISO()
      }).then(r => {
        setFloorPlan(r.data);
        setOpen(true);
      });
    }
  };

  return (
    <>
      {/* when the item is not assigned */}
      {!params.floorName && !params.locationName && (
        <Typography data-testid={"notPlaced"}>{t("Not placed")}</Typography>
      )}

      {/* when the item is assigned */}
      {(placeInventoryId !== null || zoneInventoryId !== null) &&
        params.floorName &&
        params.locationName && (
          <Button
            data-testid={"show-assigned-floor"}
            onClick={showFloorByInventoryId}
            size={"small"}
            color={"primary"}
          >
            {!!placeInventoryId ? t("Show Workplace") : t("Show Zone")}
          </Button>
        )}
    </>
  );
};

export default ShowAssignedFloor;
