import i18n from "i18next";
import React, { Dispatch, SetStateAction } from "react";
import { AppDispatch } from "../../app/store";
import { UserOrEmailMapping } from "../../features/Admin/typings/admin.types";
import { setInputs } from "../../features/Booking-Form/slices/booking.slice";
import {
  BookingType,
  NotSerializedBookingInputs,
  NotSerializedBookingMode
} from "../../features/Booking-Form/typings/booking-inputs";
import { BR, RestrictionZone } from "../../features/Booking-Form/typings/booking.types";
import { TeamMember } from "../../features/Booking-Form/typings/team-member";
import { HomeOfficeRatio } from "../../features/Dashboard/dashboard.component";
import {
  AdvancedBookingSettings,
  PricingModel,
  UserInformation
} from "../../features/Login/typings/login.types";
import { MS } from "../Dialog/form-dialog.component";
import { Weekdays } from "../Pickers/WeekdayPicker/weekday-picker.component";
import { Colleague } from "../../features/Connections/types/Colleague.type";
import * as _ from "lodash";

// refactor this to make it testable!
/* istanbul ignore next */
export const WorkingDays = (mobileWorkingWeekdays: Weekdays | undefined) =>
  !mobileWorkingWeekdays?.sunday ||
  !mobileWorkingWeekdays?.saturday ||
  !mobileWorkingWeekdays?.friday ||
  !mobileWorkingWeekdays?.thursday ||
  !mobileWorkingWeekdays?.wednesday ||
  !mobileWorkingWeekdays?.tuesday ||
  !mobileWorkingWeekdays?.monday;

export const hoursLeftPolicy = (homeOfficeRatio: HomeOfficeRatio) => {
  if (
    homeOfficeRatio.minMobileWorkingBookingHours - homeOfficeRatio.mobileWorkingBookingHours <
    0
  ) {
    return 0;
  } else {
    return homeOfficeRatio.minMobileWorkingBookingHours - homeOfficeRatio.mobileWorkingBookingHours;
  }
};

export const homeOfficePolicyText = (homeOfficeRatio: HomeOfficeRatio) => {
  if (homeOfficeRatio.minMobileWorkingBookingHours !== 0) {
    return `${hoursLeftPolicy}  ${i18n.t("hours left")}`;
  } else {
    return i18n.t("no policy");
  }
};

export const automatedBookingTextToggle = (automatedBookingActivated: boolean) =>
  automatedBookingActivated
    ? i18n.t("_automatedWorkplaceBookingToggle")
    : i18n.t("_automatedWorkplaceBookingToggleOff");

export const enterpriseOrGrowth = (pricingModels: PricingModel[]) =>
  pricingModels.includes(PricingModel.ENTERPRISE) || pricingModels.includes(PricingModel.GROWTH);

export const initSetTeam = (
  inputs: NotSerializedBookingInputs,
  setTeamBookingMember: React.Dispatch<React.SetStateAction<TeamMember[]>>,
  userInformation: UserInformation
) => {
  if (inputs?.usersBookedFor?.length !== 0 && inputs?.usersBookedFor) {
    setTeamBookingMember(inputs?.usersBookedFor);
  } else {
    setTeamBookingMember([
      {
        firstName: userInformation.firstName,
        surname: userInformation.surname,
        isExternal: false,
        userId: userInformation.sub,
        photoUrl: userInformation.photoUrl,
        email: userInformation.email
      }
    ]);
  }
};

export const getBookingType = (mode: NotSerializedBookingMode) => {
  if (mode === BookingType.TEAM) {
    return BookingType.WORKPLACE;
  }
  return mode;
};

export const handleActivityBasedRestriction = (
  inputs: NotSerializedBookingInputs,
  dispatch: AppDispatch,
  setUsers: Dispatch<SetStateAction<UserOrEmailMapping[]>>,
  users: UserOrEmailMapping[],
  sub: string,
  remoteUsers: Colleague[]
) => {
  if (!inputs.activityBasedBooking) {
    dispatch(setInputs({ zoneAccess: [] }));
    if (users.length === 0) {
      const data = remoteUsers.filter((user: { userId: string }) => user.userId !== sub);
      setUsers(data as any);
    }
  }
};

/**
 * @description Takes the input restriction object and sets it to the booking form inputs, then continues to next step.
 * @version 0.1.0
 */
export const continueStepRestriction = (
  teamRestrictions: RestrictionZone,
  dispatch: AppDispatch,
  nextStep: () => void
) => {
  if (teamRestrictions.company) {
    dispatch(
      setInputs({
        restrictionObject: {
          company: teamRestrictions.company,
          object: teamRestrictions.object
        }
      })
    );
    nextStep();
  } else alert(i18n.t("You haven't selected any company!"));
};

export const handleDialogRestriction = (
  modal: MS<BR[]>,
  setModal: Dispatch<SetStateAction<MS<BR[]>>>,
  dispatch: AppDispatch,
  inputs: NotSerializedBookingInputs,
  teamRestrictions: RestrictionZone,
  nextStep: () => void,
  pricingModels: PricingModel[]
) => {
  const del = modal.data;
  if (!del) return;
  const serialized = del.map(r => ({
    ...r,
    start: r.start,
    end: r.end
  }));
  dispatch(setInputs({ deletedRestrictionObjects: serialized }));
  if (inputs.restrictionObject.object.type || !pricingModels.includes(PricingModel.ENTERPRISE)) {
    continueStepRestriction(teamRestrictions, dispatch, nextStep);
  } else setModal({ isOpen: false });
};

export function findMaxAdvancedBookingSettingPerType(
  mode: NotSerializedBookingMode,
  advancedBookingSettings: AdvancedBookingSettings[] | undefined
): number {
  if (!advancedBookingSettings?.length) return 6;
  let length: number | undefined;

  switch (mode) {
    case BookingType.WORKPLACE:
      length = advancedBookingSettings?.find(
        s => s.bookingType === BookingType.WORKPLACE
      )?.maxWeeksInAdvance;
      break;
    case BookingType.PARKINGPLACE:
      length = advancedBookingSettings?.find(
        s => s.bookingType === BookingType.PARKINGPLACE
      )?.maxWeeksInAdvance;
      break;
    case BookingType.ELECTRICCHARGINGSTATIONPLACE:
      length = advancedBookingSettings?.find(
        s => s.bookingType === BookingType.ELECTRICCHARGINGSTATIONPLACE
      )?.maxWeeksInAdvance;
      break;
    case BookingType.CONFERENCEZONE:
      length = advancedBookingSettings?.find(
        s => s.bookingType === BookingType.CONFERENCEZONE
      )?.maxWeeksInAdvance;
      break;
    default:
      return 6;
  }

  return length ?? 6;
}

export const filterExistingExternal = (
  inputs: NotSerializedBookingInputs,
  userInformation: UserInformation,
  options: TeamMember[]
) => {
  if (inputs.usersBookedFor && inputs.usersBookedFor.length > 0) {
    // deduct current user from the array
    const deductCurrentUser = inputs.usersBookedFor.filter(
      user =>
        user.userId !== userInformation.sub ||
        user.firstName !== userInformation.firstName ||
        user.surname !== userInformation.surname
    );
    // deduct option(team member) from the array
    const existingExternal = deductCurrentUser.filter(
      user => !options.some(option => option.userId === user.userId)
    );

    if (existingExternal.length) {
      const uniqExternal = _.uniqWith(
        existingExternal,
        (extA, extB) =>
          // first and surname can be same so check email too
          extA.firstName === extB.firstName &&
          extA.surname === extB.surname &&
          extA.email === extB.email
      );

      return uniqExternal;
    }
  }
  return [];
};
