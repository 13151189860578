import React, { Dispatch, SetStateAction, useState } from "react";
import { Box, Button, Grid, IconButton, Popover, useMediaQuery, useTheme } from "@mui/material";
import { Close, LockOpen } from "@mui/icons-material";
import { MbscCalendarEvent } from "@mobiscroll/react";
import { DateTime } from "luxon";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useSelector } from "../../app/helpers";
import { RootState } from "../../app/rootReducer";
import Features from "../../features.json";
import { BookingType } from "../../features/Booking-Form/typings/booking-inputs";
import { BookingScheduleInterface } from "../../features/Booking-Form/typings/booking.types";
import { TeamMember } from "../../features/Booking-Form/typings/team-member";
import { useRemoteFetchUserInfoForAllUsers } from "../../hooks/Remote/User/UserInfo/useRemoteFetchUserInfoForAllUsers";
import { useRemoteFetchUserSettings } from "../../hooks/Remote/User/useRemoteFetchUserSettings";
import useTimezone from "../../hooks/useTimezone/useTimezone";
import { updateNoPlaceZoneBookingScheduleAttributes } from "../../utils/axios-requests";
import InputDialogScheduleConferenceDetails from "../Dialog/partials/input-dialog-schedule-conference-details.component";
import InputDialogSchedule from "../Dialog/partials/input-dialog-schedule.component";
import { ScheduleEventCheckinButton } from "./schedule-event-checkin-button.component";
import { ScheduleEventDeleteButton } from "./schedule-event-delete-button.component";
import { ScheduleEventExternalUser } from "./schedule-event-external-user.component";
import { ScheduleEventLocation } from "./schedule-event-location.component";
import { ScheduleEventBookingSubject } from "./schedule-event-popover-booking-subject";
import { ScheduleEventBookingTypeContents } from "./schedule-event-popover-booking-type-contents";
import { ScheduleEventBookingHeaderGuestContents } from "./schedule-event-popover-header-guest-contents";
import { ScheduleEventTime } from "./schedule-event-time.component";
import { MsTeamsLinkContents } from "./schedule-event-popover-msTeams-link-Contents.component";

type P = {
  anchorEl: any;
  isOpen: boolean;
  onClose: () => void;
  schedule?: BookingScheduleInterface;
  allSchedules?: BookingScheduleInterface[];
  calendarData: MbscCalendarEvent[];
  onCalendarDataChange: (events: MbscCalendarEvent[]) => void;
  onScheduleChange: (schedule: BookingScheduleInterface) => void;
  selectedColleagues?: TeamMember[];
  onClickUnlock?: () => void;
  refetchAllSchedule: () => void;
  setDeletingBookings?: (d: boolean) => void;
  setCalendarData: Dispatch<SetStateAction<MbscCalendarEvent[]>>;
};

/**
 * shown when viewing information about a booking in the calendar
 * contains buttons to check in or out
 */
export const ScheduleEventPopover: React.FC<P> = ({
  anchorEl,
  isOpen,
  onClose,
  schedule,
  allSchedules,
  selectedColleagues,
  calendarData,
  onCalendarDataChange,
  onScheduleChange,
  onClickUnlock,
  refetchAllSchedule,
  setDeletingBookings,
  setCalendarData
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { userInformation } = useSelector((state: RootState) => state.login);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  // Guests
  const [showGuestsModal, setShowGuestsModal] = useState(false);
  // Show Details - conference zones
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);

  const { data: userSettings } = useRemoteFetchUserSettings();
  const { data: users } = useRemoteFetchUserInfoForAllUsers();

  const deviceTimezone = useTimezone();

  if (!schedule) return null;

  return (
    <>
      <Popover
        // this popover and the unlock dialog both want to force focus on themselves
        // by disabling this for the popover, the dialog now can have focus without error
        // this can be refined by providing whether the unlock dialog is open or not
        // then, the popover can have focus as long as the unlock dialog is not open
        disableEnforceFocus={true}
        open={isOpen}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        slotProps={{ paper: { sx: { backgroundImage: "none" } } }}
      >
        <Grid
          container
          sx={{
            padding: "0.5rem 1rem 1rem 1rem",
            borderRadius: "1rem",
            width: "32rem",
            [theme.breakpoints.down("md")]: {
              width: "100%" // fit the screen size on smaller screens
            }
          }}
          gap={1.2}
          data-testid="schedule-event-popover-parent"
        >
          <InputDialogSchedule
            mode={schedule.bookingType !== "conferencezone"}
            appointmentDataId={{ id: schedule.id }}
            selectedUserBookedFor={schedule.userBookedFor}
            disabled={
              DateTime.fromISO(schedule.endDate).valueOf() < DateTime.now().valueOf() ||
              (selectedColleagues &&
                !selectedColleagues
                  .map(colleague => colleague.userId)
                  .includes(userInformation.sub)) ||
              schedule.isGuest
            }
            onClose={() => setShowGuestsModal(false)}
            open={showGuestsModal}
            schedule={schedule}
            refetchAllSchedule={refetchAllSchedule}
          />
          <InputDialogScheduleConferenceDetails
            userIds={
              selectedColleagues
                ? selectedColleagues.map((user: { userId: string }) => user.userId)
                : [userInformation.sub]
            }
            startDate={schedule.startDate}
            endDate={schedule.endDate}
            appointmentDataId={{ id: schedule.id }}
            selectedUserBookedFor={schedule.userBookedFor}
            disabled={
              DateTime.fromISO(schedule.endDate).valueOf() < DateTime.now().valueOf() ||
              (selectedColleagues &&
                !selectedColleagues
                  .map(colleague => colleague.userId)
                  .includes(userInformation.sub))
            }
            open={detailsModalOpen}
            scheduleData={schedule}
            isEditable={schedule.userBookedFor !== userInformation.sub}
            onClose={() => setDetailsModalOpen(false)}
            refetchAllSchedule={refetchAllSchedule}
          />
          <Grid
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "flex-end" }}
            data-testid="schedule-event-popover-top"
          >
            <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
              {/* display schedule booking type icon and type name */}
              <ScheduleEventBookingTypeContents
                schedule={schedule}
                userId={userInformation.sub}
                bookingUser={users && users.find(user => user.userId === schedule?.userBookedFor)}
              />

              {/* display text if the schedule is for guest */}
              {!!schedule.isGuest && !isMobile && (
                <ScheduleEventBookingHeaderGuestContents
                  userId={userInformation.sub}
                  schedule={schedule}
                  calendarData={calendarData}
                  onCalendarDataChange={onCalendarDataChange}
                  onClose={onClose}
                  refetchAllSchedule={refetchAllSchedule}
                />
              )}
            </Grid>

            {DateTime.fromISO(schedule.startDate).valueOf() > DateTime.now().valueOf() &&
              schedule.userBookedFor === userInformation.sub && (
                <ScheduleEventDeleteButton
                  schedule={schedule}
                  calendarData={calendarData}
                  onCalendarDataChange={onCalendarDataChange}
                  onClose={onClose}
                  setDeletingBookings={setDeletingBookings}
                />
              )}
            <IconButton
              sx={{ color: "inherit", alignSelf: "center" }}
              onClick={onClose}
              data-testid="schedule-item-close-btn"
              size="large"
            >
              <Close />
            </IconButton>
          </Grid>

          {/* display ms teams meeting link button */}
          {schedule.bookingType === BookingType.CONFERENCEZONE && (
            <MsTeamsLinkContents isMobile={isMobile} selectedSchedule={schedule} />
          )}

          {!!schedule.isGuest && isMobile && (
            <Grid item sx={{ display: "flex", alignItems: "center" }}>
              {/* display text if the schedule is for guest in mobile mode*/}
              <ScheduleEventBookingHeaderGuestContents
                userId={userInformation.sub}
                schedule={schedule}
                calendarData={calendarData}
                onCalendarDataChange={onCalendarDataChange}
                onClose={onClose}
                refetchAllSchedule={refetchAllSchedule}
              />
            </Grid>
          )}

          {/* display schedule subject when conferencezone booking */}
          {schedule.bookingType === BookingType.CONFERENCEZONE && schedule.subject && (
            <ScheduleEventBookingSubject
              subject={schedule.subject}
              isEditable={schedule.userBookedFor === userInformation.sub}
              schedule={schedule}
              onEditSubject={v => {
                const updateData = {
                  noPlaceZoneBookingId: schedule.id,
                  subject: v
                };

                return updateNoPlaceZoneBookingScheduleAttributes(updateData)
                  .then(() => {
                    enqueueSnackbar(t("Successfully edited conference subject"), {
                      variant: "success"
                    });
                    refetchAllSchedule();
                  })
                  .catch(() =>
                    enqueueSnackbar(t(`Error while editing subject`), { variant: "error" })
                  );
              }}
            />
          )}

          <ScheduleEventLocation schedule={schedule} />

          {/** on the left side, display the timezone the user has chosen while booking */}
          <Grid container item flexDirection={{ xs: "column", md: "row" }}>
            <ScheduleEventTime
              schedule={schedule}
              timezone={schedule.timezone}
              calendarData={calendarData}
              setCalendarData={setCalendarData}
              refetchAllSchedule={refetchAllSchedule}
            />
            {/** divider to have a nice column layout */}
            <Box flex={1} />
            {/** if the schedule's timezone isn't the users default, render users timezone too */}
            {userSettings?.timezone && userSettings.timezone !== schedule.timezone && (
              <ScheduleEventTime
                schedule={schedule}
                timezone={deviceTimezone}
                calendarData={calendarData}
                setCalendarData={setCalendarData}
                refetchAllSchedule={refetchAllSchedule}
              />
            )}
          </Grid>
          <ScheduleEventExternalUser schedule={schedule} />

          <Grid
            data-testid="schedule-event-popover-btn-container"
            container
            item
            gap={1}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: isMobile ? "wrap" : "nowrap",
              flexDirection: "row",
              mt: 1,
              [theme.breakpoints.down("md")]: {
                gap: "8px" // on smaller screens make gap between buttons smaller
              },
              "@media (max-width: 300px)": {
                columnGap: "2px" // on extra small screens make the gap even less
              }
            }}
          >
            {schedule.locationName !== "Mobile Working" && (
              <Grid item xs={5} data-testid="schedule-item-show-btn-grid">
                <Button
                  data-testid="schedule-item-show-btn"
                  onClick={() => onScheduleChange(schedule)}
                  color="primary"
                  variant="contained"
                  sx={{
                    height: "100%",
                    width: "100%",
                    [theme.breakpoints.down("md")]: {
                      padding: "5px 10px",
                      fontSize: "0.725rem"
                    }
                  }}
                >
                  {schedule.bookingType === BookingType.CONFERENCEZONE
                    ? t("Show Zone")
                    : t("Show place")}
                </Button>
              </Grid>
            )}
            {schedule.bookingType === BookingType.CONFERENCEZONE && (
              <Grid
                item
                xs={5}
                data-testid="show-details-modal-btn-grid"
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Button
                  color={"primary"}
                  data-testid="show-details-modal-button"
                  variant="contained"
                  onClick={() => {
                    setDetailsModalOpen(true);
                  }}
                  sx={{
                    height: "100%",
                    width: "100%",
                    [theme.breakpoints.down("md")]: {
                      padding: "5px 10px",
                      fontSize: "0.725rem"
                    }
                  }}
                >
                  {t("Show Details")}
                </Button>
              </Grid>
            )}

            <ScheduleEventCheckinButton
              selectedSchedule={schedule}
              allSchedules={allSchedules}
              refetchAllSchedule={refetchAllSchedule}
            />

            {(schedule.bookingType === BookingType.PLACEZONE ||
              schedule.bookingType === BookingType.CONFERENCEZONE) && (
              <>
                <Grid
                  item
                  xs={5}
                  data-testid="show-guests-modal-btn-grid"
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Button
                    color={"primary"}
                    data-testid="show-guests-modal-button"
                    variant="contained"
                    onClick={() => setShowGuestsModal(true)}
                    sx={{
                      height: "100%",
                      width: "100%",
                      [theme.breakpoints.down("md")]: {
                        padding: "5px 10px",
                        fontSize: "0.725rem"
                      }
                    }}
                  >
                    {schedule.bookingType !== BookingType.CONFERENCEZONE
                      ? t("Show Zone Access")
                      : t("Show Guests")}
                  </Button>
                </Grid>
              </>
            )}

            {Features.Unlockables &&
              onClickUnlock &&
              schedule.doorLockData &&
              schedule.doorLockData.length > 0 && (
                <Grid
                  item
                  data-testid="doorlock-btn-grid"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <IconButton
                    onClick={onClickUnlock}
                    sx={{
                      padding: "4px"
                    }}
                    size="large"
                  >
                    <LockOpen />
                  </IconButton>
                </Grid>
              )}
          </Grid>
        </Grid>
      </Popover>
    </>
  );
};
