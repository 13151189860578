import { Box, IconButton } from "@mui/material";
import { EditCalendar, Lock, LockOutlined, Update, UpdateOutlined } from "@mui/icons-material";
import { PermissionUnitEntries } from "../PermissionSelect";

type PermissionSelectSingleChipProps = {
  type: string;
  option: PermissionUnitEntries;
  handleStrictPermPopoverOpen: (event: React.MouseEvent<HTMLElement>) => void;
  handleStrictPermPopoverClose: () => void;
  handleWkdayPermPopoverOpen: (event: React.MouseEvent<HTMLElement>) => void;
  handleWkdayPermPopoverClose: () => void;
  handleTimeRelatPermPopoverOpen: (event: React.MouseEvent<HTMLElement>) => void;
  handleTimeRelatPermPopoverClose: () => void;
  setIsWkdayPermDialogOpen: (o: boolean) => void;
  setIsTimeRelatPermDialogOpen: (o: boolean) => void;
  handleStricPermChange: (e: any, value: any) => void;
  setSelectOpt: (o: PermissionUnitEntries | null) => void;
};

export function PermissionSelectSingleChip(props: PermissionSelectSingleChipProps) {
  const {
    type,
    option,
    handleStrictPermPopoverOpen,
    handleStrictPermPopoverClose,
    handleWkdayPermPopoverOpen,
    handleWkdayPermPopoverClose,
    handleTimeRelatPermPopoverOpen,
    handleTimeRelatPermPopoverClose,
    setIsWkdayPermDialogOpen,
    setIsTimeRelatPermDialogOpen,
    handleStricPermChange,
    setSelectOpt
  } = props;

  return (
    <Box data-testid="perm-type-btn-group" sx={{ pl: 0.7 }}>
      <IconButton
        data-testid="stric-perm-btn"
        sx={{ padding: 0.1 }}
        onMouseOver={handleStrictPermPopoverOpen}
        onMouseLeave={handleStrictPermPopoverClose}
        onClick={e => handleStricPermChange(e, option)}
      >
        {option.enforceUsage ? <Lock color={"info"} /> : <LockOutlined />}
      </IconButton>

      {/* a button to open the weekday permission modal open */}
      <IconButton
        data-testid="weekday-perm-modal-open-btn"
        sx={{ padding: 0.1 }}
        onMouseOver={handleWkdayPermPopoverOpen}
        onMouseLeave={handleWkdayPermPopoverClose}
        onClick={() => {
          setSelectOpt(option);
          setIsWkdayPermDialogOpen(true);
        }}
      >
        <EditCalendar />
      </IconButton>

      {/* a button to open the time-relevant permission modal open */}
      {type === "place" && (
        <IconButton
          data-testid="time-perm-modal-open-btn"
          sx={{ padding: 0.1 }}
          onMouseOver={handleTimeRelatPermPopoverOpen}
          onMouseLeave={handleTimeRelatPermPopoverClose}
          onClick={() => {
            setSelectOpt(option);
            setIsTimeRelatPermDialogOpen(true);
          }}
        >
          {option.hoursInAdvance > 0 ? <Update color={"info"} /> : <UpdateOutlined />}
        </IconButton>
      )}
    </Box>
  );
}
