import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
  useTheme
} from "@mui/material";
import { Close } from "@mui/icons-material";
import unionBy from "lodash/unionBy";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { getNonColleagues, updateColleagues } from "../../../utils/axios-requests";
import { Colleague } from "../../../features/Connections/types/Colleague.type";

/**
 * @description Modal that displays current zone access users and lets the user edit them if allowed to.
 * @param props.open as a boolean which defines the open state of the modal.
 * @param props.onClose as a function which defines the action when closing the modal.
 * @param props.appointmentData as the data of the appointment including start and end dates and id.
 * @param props.disabled as a boolean which defines disabled state of the confirm button.
 * @version 0.1.0
 */

export interface SimpleDialogProps {
  open: boolean;
  onClose: (value: string) => void;
  disabled?: boolean;
}

export default function InputDialogColleagues(props: SimpleDialogProps) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  const { onClose, open } = props;

  const [nonColleagues, setNonColleagues] = useState<Colleague[]>([]);

  const handleClose = () => onClose("false");

  const handleSave = () => {
    onClose("false");
    const colleagues: any = [];
    selectedUsers.forEach(el => {
      const updatedColleague = { ...el };
      updatedColleague.friendsReqPermission = false;
      updatedColleague.userId = el.userId;
      updatedColleague.friendsReqColleague = true;
      updatedColleague.friendsColleague = true;
      updatedColleague.scheduleColleague = false;
      updatedColleague.scheduleReqColleague = true;
      updatedColleague.teamBookingColleague = false;
      updatedColleague.teamBookingReqColleague = true;
      updatedColleague.friendsPermission = false;
      updatedColleague.schedulePermission = false;
      updatedColleague.scheduleReqPermission = false;
      updatedColleague.teamBookingPermission = false;
      updatedColleague.teamBookingReqPermission = false;

      colleagues.push(updatedColleague);
    });

    if (colleagues.length === 0) return;
    updateColleagues(colleagues)
      .then(() => {
        setSelectedUsers([]);
        enqueueSnackbar(t("Successfully sent a request"), { variant: "success" });
      })
      .catch(() => {
        enqueueSnackbar(t(`Error while sending request`), {
          variant: "error"
        });
      });

    setNonColleagues(unionBy(nonColleagues, selectedUsers, "email"));
  };

  useEffect(() => {
    if (open) getNonColleagues().then(res => setNonColleagues(res.data));
  }, [open]);
  const [selectedUsers, setSelectedUsers] = useState<Colleague[]>([]);

  return (
    <>
      <Dialog maxWidth="sm" fullWidth aria-labelledby="simple-dialog-title" open={open}>
        <DialogTitle sx={{ margin: 0, padding: theme.spacing(2) }}>
          <Typography variant="h6">{t("Add Colleagues")}</Typography>
          <IconButton
            sx={{
              position: "absolute",
              right: theme.spacing(1),
              top: theme.spacing(1),
              color: theme.palette.grey[500]
            }}
            onClick={handleClose}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ padding: theme.spacing(2) }}>
          <Autocomplete
            data-testid={"colleagues-add-selection"}
            multiple
            disableCloseOnSelect
            id="tags-outlined7"
            options={nonColleagues}
            filterSelectedOptions
            getOptionLabel={option => option.firstName + " " + option.surname}
            sx={{ width: "100%" }}
            onChange={(event, values) => {
              event.persist();
              setSelectedUsers(values);
            }}
            value={selectedUsers || []}
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                fullWidth
                placeholder={t("Choose your colleagues")}
                className="autocompletefield"
              />
            )}
          />
        </DialogContent>
        <Button
          data-testid="colleagues-add-confirm-btn"
          disabled={props.disabled}
          onClick={handleSave}
        >
          {t("Confirm")}
        </Button>
      </Dialog>
    </>
  );
}
