import React, { useEffect, useState } from "react";
import { Grid, IconButton, Popover, Skeleton } from "@mui/material";
import { Info } from "@mui/icons-material";
import CompanyCard from "./company-card.component";
import { MetaExplained } from "./meta-defaults-explained.component";
import MetaAdministrationDialog from "./MetaAdministrationDialog/meta-administration-dialog.component";
import { useRemoteFetchMetadata } from "../../hooks/Remote/Metadata/useRemoteFetchMetadata";
import { useRemoteFetchCompanies } from "../../hooks/Remote/Companies/useRemoteFetchCompanies";

type P = {
  enableNext?: React.Dispatch<React.SetStateAction<boolean>>;
  setActiveStep?: React.Dispatch<React.SetStateAction<number>>;
};

/**
 * @description Component that renders the meta data administration.
 * @version 0.1.0
 */
const MetaAdministration: React.FC<P> = ({ enableNext }) => {
  const [anchorElMeta, setAnchorElMeta] = React.useState<HTMLButtonElement | null>(null);
  const explanationOpen = Boolean(anchorElMeta);
  const [anchorEl] = useState<any>(null);
  const [id] = useState();
  const hideInfoIcon = false;

  const {
    data: remoteMetadata,
    refetch: refetchMetadata,
    isLoading: metadataIsLoading
  } = useRemoteFetchMetadata();
  const {
    data: remoteCompanies,
    refetch: refetchCompanies,
    isLoading: companiesAreLoading
  } = useRemoteFetchCompanies();

  async function onConfirmCreateCompany() {
    await refetchCompanies();
    await refetchMetadata();
  }

  useEffect(() => {
    if (enableNext && remoteMetadata && remoteMetadata.length > 0) enableNext(true);
  }, [enableNext, remoteMetadata]);

  return (
    <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
      <Grid container style={{ width: "100%", marginBottom: 10 }} justifyContent={"space-between"}>
        {/* currently, hide the icon */}
        <Grid />
        {hideInfoIcon && (
          <IconButton
            data-testid="meta-explanation-info-icon"
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => setAnchorElMeta(e.currentTarget)}
            size="large"
          >
            <Info />
          </IconButton>
        )}

        <MetaAdministrationDialog onConfirm={onConfirmCreateCompany} enableNext={enableNext} />
      </Grid>

      {(metadataIsLoading || companiesAreLoading) && (
        <Skeleton variant={"rectangular"} sx={{ height: 80, width: 350 }} />
      )}

      {remoteMetadata &&
        remoteCompanies &&
        remoteMetadata.length > 0 &&
        remoteMetadata.map(company => (
          <CompanyCard
            key={company.id?.toString()}
            brandColor={company.color}
            logoUrl={company.logoPath}
            companyName={
              remoteCompanies?.find(companyTenant => companyTenant.id === company.companyId)?.name
            }
            companyMetaData={company}
          />
        ))}
      <Popover
        id={id}
        open={explanationOpen}
        anchorEl={anchorEl}
        onClose={() => setAnchorElMeta(null)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left"
        }}
      >
        <MetaExplained />
      </Popover>
    </Grid>
  );
};

export default MetaAdministration;
