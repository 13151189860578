import React, { Dispatch, Fragment, SetStateAction, useEffect, useState } from "react";
import { Alert, Box, Fade, Grid, Paper, Typography, styled, useTheme } from "@mui/material";
import { EvStation, LocalParking } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "../../app/helpers";
import { RootState } from "../../app/rootReducer";
import { ReactComponent as AvatarIcon } from "../../features/Booking-Form/assets/avatar.svg";
import { ReactComponent as ConferenceIcon } from "../../features/Booking-Form/assets/conference.svg";
import { ReactComponent as TeamIcon } from "../../features/Booking-Form/assets/group.svg";
import {
  clearFloors,
  clearInputs,
  setInputs
} from "../../features/Booking-Form/slices/booking.slice";
import {
  BookingType,
  NotSerializedBookingMode
} from "../../features/Booking-Form/typings/booking-inputs";
import { LocationInventory } from "../../features/FloorManager/typings/location-inventory";
import { useRemoteFetchCompanyLocations } from "../../hooks/Remote/Location/useRemoteFetchCompanyLocations";
import { getBookingType } from "./booking.utils";
import BookingSettingsView from "./BookingSettingsView/BookingSettingsView";
import { PlaceType, ZoneType } from "../../features/Login/typings/login.types";

/**
 * @description Component that displays the various booking modes.
 * @param props.setInputs is the main state input update.
 * @param props.setPicker is the selected date and time picker mode.
 * @param props.changeAction is the function that cleans up state after going a step back.
 * @version 0.1.0
 */

type Props = {
  nextStep: () => void;
  setPicker: Dispatch<SetStateAction<boolean>>;
  scheduleOption: boolean;
  setScheduleOption: Dispatch<SetStateAction<boolean>>;
  changeAction?: () => void;
};

const FormSelection: React.FC<Props> = ({
  nextStep,
  scheduleOption,
  setScheduleOption,
  changeAction
}) => {
  const {
    userInformation: {
      sub,
      surname,
      firstName,
      email,
      placeTypes,
      zoneTypes,
      company: { meta }
    },
    settings: { preferredLocations, preferredPlaceCategories }
  } = useSelector((state: RootState) => state.login);
  const { inputs } = useSelector((state: RootState) => state.booking);
  const dispatch = useDispatch();
  const theme = useTheme();
  const { t } = useTranslation();
  const [doubleCheckLocations, setDoubleCheckLocations] = useState<LocationInventory[]>([]);

  const { data: companyLocations, isLoading: companyLocationsLoading } =
    useRemoteFetchCompanyLocations({
      isFinanceRequest: false,
      noMobileWorking: true,
      isBookingSettings: true
    });

  const paperSxProps = {
    "&:hover": {
      boxShadow: `0px 0px 0px 4px ${theme.palette.primary.main}  inset;`
    },
    cursor: "pointer",
    display: "flex",
    flexWrap: "wrap",
    boxSizing: "border-box",
    justifyContent: "center",
    flex: "1 1 100%",
    alignItems: "center",
    maxWidth: "300px",
    flexDirection: "column",
    padding: theme.spacing(3),
    margin: theme.spacing(2)
  };

  /**
   * @description function that passes the current user as person who is booking.
   * @version 0.1.0
   * @param mode
   */

  const onModeChange = (mode: NotSerializedBookingMode) => {
    changeAction && changeAction();
    setScheduleOption(true);
    dispatch(clearInputs());
    dispatch(clearFloors());

    dispatch(
      setInputs({
        mode: mode,
        bookingType: getBookingType(mode),
        usersBookedFor: [
          {
            userId: sub,
            firstName,
            surname: surname,
            isExternal: false,
            email: email
          }
        ],
        isUserBookedBy: true,
        automatedSeating: false
      })
    );
    nextStep();
  };

  const checkPreferredAndDouble =
    preferredLocations &&
    preferredLocations.length > 0 &&
    preferredPlaceCategories &&
    preferredPlaceCategories.length > 0 &&
    doubleCheckLocations.length > 0;

  useEffect(() => {
    if (companyLocations) setDoubleCheckLocations(companyLocations);
  }, [companyLocationsLoading, companyLocations]);

  useEffect(() => {
    if (inputs.booked) {
      dispatch(clearInputs());
    }
  }, [inputs, dispatch]);

  useEffect(() => {
    setScheduleOption(false);
  }, [setScheduleOption]);

  return (
    <Fragment>
      <Grid justifyContent="center" container>
        {!scheduleOption && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems={"center"}
            data-testid="box"
            width={"100%"}
            flexDirection={"column"}
          >
            <BookingSettingsView />

            {checkPreferredAndDouble ? (
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems="stretch"
                flexDirection={"row"}
                flexWrap="wrap"
                data-testid="select-booking-mode"
                width={"75vw"}
              >
                {fitAndCorrectType(BookingType.WORKPLACE, placeTypes, zoneTypes) && (
                  <Fade timeout={500} in>
                    <Paper
                      onClick={() => onModeChange(BookingType.WORKPLACE)}
                      sx={{ ...paperSxProps }}
                      data-testid="workplace-booking-mode-btn"
                    >
                      <Typography variant={"h6"}>{t("Workplace")}</Typography>
                      <StyledIconComponent theme={theme}>
                        <AvatarIcon width={"120px"} height={"90px"} />
                      </StyledIconComponent>
                    </Paper>
                  </Fade>
                )}

                {meta.teamBookingAllowed &&
                  fitAndCorrectType(BookingType.WORKPLACE, placeTypes, zoneTypes) && (
                    <Fade timeout={800} in>
                      <Paper
                        onClick={() => onModeChange(BookingType.TEAM)}
                        sx={{ ...paperSxProps }}
                        data-testid="team-booking-mode-btn"
                      >
                        <Typography variant={"h6"}>{t("Team")}</Typography>
                        <StyledIconComponent theme={theme}>
                          <TeamIcon width={"120px"} height={"90px"} />
                        </StyledIconComponent>
                      </Paper>
                    </Fade>
                  )}

                {fitAndCorrectType(BookingType.CONFERENCEZONE, placeTypes, zoneTypes) && (
                  <Fade timeout={1400} in>
                    <Paper
                      onClick={() => onModeChange(BookingType.CONFERENCEZONE)}
                      sx={{ ...paperSxProps }}
                      data-testid="conference-booking-mode-btn"
                    >
                      <Typography variant={"h6"}>{t("Conference")}</Typography>
                      <StyledIconComponent theme={theme}>
                        <ConferenceIcon width={"120px"} height={"90px"} />
                      </StyledIconComponent>
                    </Paper>
                  </Fade>
                )}

                {fitAndCorrectType(BookingType.PARKINGPLACE, placeTypes, zoneTypes) && (
                  <Fade timeout={1700} in>
                    <Paper
                      onClick={() => onModeChange(BookingType.PARKINGPLACE)}
                      sx={{ ...paperSxProps }}
                      data-testid="parkingPlace-booking-mode-btn"
                    >
                      <Typography variant={"h6"}>{t("Parking")}</Typography>
                      <StyledIconComponent theme={theme}>
                        <LocalParking width={"120px"} height={"90px"} />
                      </StyledIconComponent>
                    </Paper>
                  </Fade>
                )}

                {fitAndCorrectType(
                  BookingType.ELECTRICCHARGINGSTATIONPLACE,
                  placeTypes,
                  zoneTypes
                ) && (
                  <Fade timeout={2000} in>
                    <Paper
                      onClick={() => onModeChange(BookingType.ELECTRICCHARGINGSTATIONPLACE)}
                      sx={{ ...paperSxProps }}
                      data-testid="electricchargingplace-booking-mode-btn"
                    >
                      <Typography variant={"h6"}>{t("Electric Charging")}</Typography>
                      <StyledIconComponent theme={theme}>
                        <EvStation width={"120px"} height={"90px"} />
                      </StyledIconComponent>
                    </Paper>
                  </Fade>
                )}
              </Box>
            ) : (
              <Alert style={{ marginTop: 10 }} severity="warning">
                {t("Please select preferred locations")}
              </Alert>
            )}
          </Box>
        )}
      </Grid>
    </Fragment>
  );
};

export default FormSelection;

export const StyledIconComponent = styled("svg")(({ theme }) => ({
  width: "120px",
  height: "90px",
  marginBottom: "10px",
  "& path": {
    fill: theme.palette.text.primary
  }
}));

export const fitAndCorrectType = (
  typeName: string,
  placeTypes: PlaceType[],
  zoneTypes: ZoneType[]
) =>
  placeTypes.filter(type => type.name === typeName).length > 0 ||
  zoneTypes.filter(type => type.name === typeName).length > 0;
