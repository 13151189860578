import { PropsWithChildren, useEffect, useState } from "react";
import { Box, Tab } from "@mui/material";
import { ReportTabs, ReportUserListTab } from "../components";
import { SupervisorTransferRights } from "../../../features/Supervisor/supervisor-transfer-rights.component";
import {
  RemoteUserReport,
  useRemoteFetchEmployeeReport
} from "../../../hooks/Remote/useRemoteFetchEmployeeReport";
import { calculateAllLocationsFromAllUsers } from "./functions";
import { DateTime } from "luxon";
import { createEmployeeDateMap } from "../HumanResourcesReport/HumanResourcesReport";
import { useTranslation } from "react-i18next";
import { GenericLocationUsage } from "../typings/GenericLocationUsage";
import { EmployeeReportType } from "../typings/EmployeeReportType";
import PlaceBookingApproval from "../../../features/Approval/place-booking-approval.component";

export const APPROVAL_TYPE_ARRAY = [
  { name: "Workplaces", value: "workplaces" },
  { name: "Zones", value: "zones" },
  { name: "Categories", value: "categories" },
  { name: "Mobile Working", value: "mobile" }
];

export type DateRange = { start: string; end: string };

/**
 * @description Component that displays the supervisor report.
 * @version 0.2.0
 */
const SupervisorReport = () => {
  const [allLocations, setAllLocations] = useState<GenericLocationUsage[]>();
  const [employeeDates, setEmployeeDates] = useState<Map<string, DateRange> | undefined>();
  const [selectedTab, setSelectedTab] = useState(0);
  const [reportType, setReportType] = useState<EmployeeReportType | undefined>();

  const [dateRange, setDateRange] = useState({
    start: DateTime.now().minus({ day: 30 }),
    end: DateTime.now()
  });

  const { t } = useTranslation();

  const { data: employeeReport, isLoading: employeeReportIsLoading } = useRemoteFetchEmployeeReport(
    {
      reportType: EmployeeReportType.SUPERVISOR,
      start: dateRange.start.toFormat("yyyy-MM-dd"),
      end: dateRange.end.toFormat("yyyy-MM-dd")
    }
  );

  function updateEmployeeDates(nextEmployeeReport: RemoteUserReport) {
    setEmployeeDates(createEmployeeDateMap(nextEmployeeReport, dateRange.start, dateRange.end));
  }

  // init all temporary date ranges
  useEffect(() => {
    if (!employeeReport) return;
    setReportType(EmployeeReportType.SUPERVISOR);
    const allLocationsFromAllUsers = calculateAllLocationsFromAllUsers(employeeReport);

    updateEmployeeDates(employeeReport);
    setAllLocations(allLocationsFromAllUsers);
  }, [employeeReport]);

  return (
    <Box>
      <ReportTabs
        data-testid="supervisor-tabs"
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      >
        <Tab label={t("_supervisorReportTab")} />
        <Tab label={t("_supervisorApprovalTab")} />
        <Tab label={t("_supervisorTransmissionTab")} />
      </ReportTabs>

      {/** List */}
      <TabPanel value={selectedTab} index={0}>
        <ReportUserListTab
          {...{
            selectedTab,
            allLocations,
            employeeDates,
            dateRange,
            setDateRange,
            employeeReport,
            employeeReportIsLoading,
            reportType
          }}
        />
      </TabPanel>

      {/** Place Booking Approval */}
      <TabPanel value={selectedTab} index={1}>
        <PlaceBookingApproval />
      </TabPanel>

      {/** Transmission */}
      <TabPanel value={selectedTab} index={2}>
        <SupervisorTransferRights />
      </TabPanel>
    </Box>
  );
};

export function TabPanel({
  value,
  index,
  children
}: PropsWithChildren<{ index: number; value: number }>) {
  return <>{index === value && children}</>;
}

export default SupervisorReport;
