import { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
  Popover,
  Input
} from "@mui/material";
import { Edit } from "@mui/icons-material";
import { useFormikContext } from "formik";
import { TwitterPicker } from "react-color";
import { useTranslation } from "react-i18next";
import useScript from "react-script-hook";
import { useDispatch, useSelector } from "../../app/helpers";
import { RootState } from "../../app/rootReducer";
import { createModal, FloorManagerModal } from "../../features/FloorManager/slices/modals.slice";
import { LocationInventory } from "../../features/FloorManager/typings/location-inventory";
import { PricingModel } from "../../features/Login/typings/login.types";
import { getMuiErrorHelperText, getMuiPropsFromFormikContext } from "../../utils/form.utils";
import { PictureDropzone } from "../Pickers/picture-dropzone.component";
import { GoogleMapPicker } from "./google-map-picker3.component";
import { NumericFormatCustom } from "./checkin-radius-numericFormat.component";
import { useFeatures } from "../FacilityManager/Hooks/useFeatures";

/**
 * Basic inputs for location inventory.
 */
const LocationInputs: React.FC = () => {
  const { t } = useTranslation();

  const formikContext = useFormikContext<LocationInventory>();
  const { values, handleChange, setFieldValue, errors } = formikContext;
  const {
    userInformation: {
      pricingModels,
      company: {
        meta: { color: brandColor }
      }
    }
  } = useSelector((state: RootState) => state.login);

  const dispatch = useDispatch();
  const { useCheckinRadius } = useFeatures();

  const googleMapsKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const [scriptLoading, scriptError] = useScript({
    src: `https://maps.googleapis.com/maps/api/js?key=${googleMapsKey}&libraries=places`
  });

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const openEl = Boolean(anchorEl);

  useEffect(() => {
    setFieldValue("bookingAllowedAt", values.allowForBooking ? new Date().toISOString() : "");
    setFieldValue("checkinRadius", values.checkinRadius ?? 1);
  }, [values.allowForBooking, values.checkinRadius, setFieldValue]);

  return (
    <>
      <TextField
        {...getMuiPropsFromFormikContext(formikContext, "name", t)}
        type="text"
        onChange={handleChange}
        margin="dense"
        fullWidth
      />
      <Grid
        container
        sx={{ justifyContent: "flex-start", alignItems: "center" }}
        data-testid="location-form-address-and-checkin-period"
      >
        {/* location form address picker */}
        <div>{!scriptLoading && !scriptError && <GoogleMapPicker />}</div>

        {pricingModels.includes(PricingModel.GROWTH) && (
          <>
            {/* checkin-period select */}
            <FormControl style={{ margin: 20, minWidth: 120 }}>
              <InputLabel id="demo-simple-select-label">{t("Check-in Period")}</InputLabel>
              <Select
                data-testid="check-in-period-select"
                sx={{ width: 150, marginLeft: 1.8 }}
                margin="dense"
                name="checkinPeriod"
                id="checkinPeriod"
                defaultValue={15}
                label={t("Check-In Period")}
                placeholder={t("30 minutes")}
                value={values.checkinPeriod}
                onChange={handleChange}
                variant={"standard"}
              >
                <MenuItem value={15}>{t("15 Minutes")}</MenuItem>
                <MenuItem value={30}>{t("30 Minutes")}</MenuItem>
                <MenuItem value={45}>{t("45 Minutes")}</MenuItem>
                <MenuItem value={-1}>{t("Unlimited")}</MenuItem>
              </Select>
            </FormControl>

            {/* checkin-radius field */}
            {useCheckinRadius && (
              <FormControl style={{ margin: 20, maxWidth: 160 }}>
                <InputLabel>{`${t("Check-In Radius")} (km)`}</InputLabel>
                <Input
                  sx={{ marginLeft: 1.8 }}
                  {...getMuiPropsFromFormikContext(formikContext, "checkinRadius", t)}
                  data-testid="check-in-radius-input"
                  value={values.checkinRadius}
                  onChange={handleChange}
                  inputComponent={NumericFormatCustom as any} // type="number"
                  inputProps={{ min: 0.1, step: 0.1 }}
                  error={!values.checkinRadius}
                />
              </FormControl>
            )}
          </>
        )}
      </Grid>
      <TextField
        {...getMuiPropsFromFormikContext(formikContext, "maxLevels", t)}
        margin="dense"
        type="number"
        label={t("Max Levels")}
        onChange={handleChange}
        InputProps={{ inputProps: { min: 1 } }}
      />
      <Grid container sx={{ paddingTop: "2%" }}>
        <Grid
          item
          xs={6}
          sx={{
            marginTop: "6px",
            marginBottom: "6px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"
          }}
        >
          {pricingModels.includes(PricingModel.ENTERPRISE) && (
            <FormControlLabel
              control={
                <Switch
                  checked={values.allowChangeDeskCost ?? false}
                  onChange={handleChange}
                  name="allowChangeDeskCost"
                  id="allowChangeDeskCost"
                  color="primary"
                />
              }
              label={t("Allow changing desk cost?")}
            />
          )}

          <FormControlLabel
            control={
              <Switch
                checked={values.allowForBooking ?? false}
                onChange={handleChange}
                name="allowForBooking"
                id="allowForBooking"
                color="primary"
              />
            }
            label={t("Allow bookings?")}
          />
        </Grid>

        <Grid
          item
          xs={6}
          sx={{ justifyContent: "flex-start", alignItems: "center", width: "100%" }}
        >
          <Typography variant={"body2"}>{t("Schedule color")}</Typography>
          <Grid item sx={{ display: "flex", alignItems: "center" }}>
            <Box
              data-testid={"box-loc-color"}
              sx={{
                background: values.color || brandColor || "",
                width: "4rem",
                height: 15,
                borderRadius: 6,
                marginRight: 2
              }}
            />
            <IconButton
              onClick={e => setAnchorEl(e.currentTarget)}
              size={"small"}
              data-testid={"change-loc-color"}
              aria-label={"change Color"}
            >
              <Edit />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>

      {/* change location color */}
      <Popover
        disableEnforceFocus={true}
        open={openEl}
        anchorEl={anchorEl}
        /* istanbul ignore next */
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
      >
        <Grid
          container
          style={{ marginTop: 10, height: 100 }}
          data-testid="twitter-color-picker-grid"
        >
          <TwitterPicker
            color={values.color || brandColor || ""}
            onChange={props => setFieldValue("color", props.hex)}
          />
        </Grid>
      </Popover>

      <PictureDropzone
        title={t("Upload an image")}
        files={(values as any).file ?? []}
        onAdd={files => {
          setFieldValue("file", files);
          setFieldValue("picturePath", "temp");
        }}
        onDelete={() => {
          setFieldValue("file", undefined);
          setFieldValue("picturePath", "");
        }}
        current={values.picturePath ?? undefined}
        error={!(values as any).file}
        helperText={t((errors as any).file)}
      />
      {values.id >= 0 && (
        <Button
          onClick={() =>
            dispatch(
              createModal({
                modalType: FloorManagerModal.DeleteLocation,
                initialValues: { name: values.name, uid: values.uid, confirmString: "" }
              })
            )
          }
        >
          {t("Delete Location")}
        </Button>
      )}
    </>
  );
};

export const ConfirmStringField: React.FC<{ placeholder?: string; label?: string }> = ({
  placeholder,
  label
}) => {
  const { handleChange, touched, errors } = useFormikContext<{ confirmString: string }>();
  const { t } = useTranslation();

  return (
    <Grid>
      <TextField
        name="confirmString"
        onChange={handleChange}
        label={label || t("Confirm")}
        placeholder={placeholder}
        {...getMuiErrorHelperText("confirmString", touched, errors)}
        fullWidth
      />
    </Grid>
  );
};

export default LocationInputs;
