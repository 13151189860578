import { Avatar, Typography } from "@mui/material";
import { MRT_Row } from "material-react-table";
import { TFunction } from "i18next";
import { UserManagementRow } from "./typings/user-management.type";

export function userRowCellPhotoAvatar(row: MRT_Row<UserManagementRow>) {
  return (
    <Avatar alt={row.original.surname} src={row.original.photoUrl ?? ""} aria-label="avatar" />
  );
}

export function userRowCellCompanyId(row: MRT_Row<UserManagementRow>) {
  return <span>{row.original.companyName}</span>;
}

export function userRowCellDepartmentId(row: MRT_Row<UserManagementRow>) {
  return <span>{row.original.departmentName}</span>;
}

export function userRowCellActive(row: MRT_Row<UserManagementRow>, t: TFunction) {
  return <Typography>{t(row.original.isActive === "active" ? "ACTIVE" : "INACTIVE")}</Typography>;
}
