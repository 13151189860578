import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import { Group, Today } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import React from "react";
import { useTranslation } from "react-i18next";
import { InfoIconWithTooltip } from "../../../../components/Title/InfoIcon";
import { updateColleagues } from "../../../../utils/axios-requests";
import { Colleague } from "../../types/Colleague.type";
type P = {
  isOpen: boolean;
  isInquiry: boolean;
  colleague: Colleague;
  onClose: () => void;
  onRefetch: () => void;
  onColleagueUpdate: (colleague: Colleague) => void;
};

export const ColleagueCardPermissionsDialog: React.FC<P> = ({
  isOpen,
  onClose,
  onRefetch,
  colleague,
  onColleagueUpdate,
  isInquiry
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const enqueueErrorSnackbar = () => {
    enqueueSnackbar("There was an error updating connection.", {
      variant: "error",
      persist: false
    });
    onRefetch();
  };

  const handleTeamBookingColleagueChange = () => {
    const updatedUser = { ...colleague };
    if (!updatedUser.teamBookingReqColleague) updatedUser.teamBookingColleague = false;
    updatedUser.teamBookingReqColleague = !colleague.teamBookingReqColleague;

    onColleagueUpdate(updatedUser);
  };

  const handleTeamBookingPermissionChange = () => {
    const updatedUser = { ...colleague };

    updatedUser.teamBookingPermission = !colleague.teamBookingPermission;
    updatedUser.teamBookingReqPermission = updatedUser.teamBookingPermission;

    if (updatedUser.teamBookingPermission) {
      updatedUser.friendsReqPermission = true;
      updatedUser.friendsPermission = true;
    }
    onColleagueUpdate(updatedUser);
  };

  const handleScheduleColleagueChange = () => {
    const updatedUser = { ...colleague };

    if (!updatedUser.scheduleReqColleague) updatedUser.scheduleColleague = false;

    updatedUser.scheduleReqColleague = !colleague.scheduleReqColleague;

    onColleagueUpdate(updatedUser);
  };

  const handleSchedulePermissionChange = () => {
    const updatedUser = { ...colleague };

    updatedUser.schedulePermission = !colleague.schedulePermission;
    updatedUser.scheduleReqPermission = updatedUser.schedulePermission;
    if (updatedUser.schedulePermission) {
      updatedUser.friendsReqPermission = true;
      updatedUser.friendsPermission = true;
    }

    onColleagueUpdate(updatedUser);
  };

  const handleSubmit = () => {
    const updatedUser = { ...colleague };
    if (!updatedUser.scheduleReqColleague) updatedUser.scheduleColleague = false;
    if (!updatedUser.teamBookingReqColleague) updatedUser.teamBookingColleague = false;
    updatedUser.friendsColleague = true;
    updatedUser.friendsReqColleague = true;

    updateColleagues([updatedUser])
      .then(() => onColleagueUpdate(updatedUser))
      .catch(enqueueErrorSnackbar);
    onClose();
  };

  const handleSubmitInquiry = () => {
    const updatedUser = { ...colleague };
    updatedUser.friendsReqPermission = true;
    updatedUser.friendsPermission = true;
    updateColleagues([updatedUser]).catch(enqueueErrorSnackbar);

    onClose();
  };

  return (
    <Dialog
      data-testid="colleague-card-permissions-dialog"
      open={isOpen}
      onClose={onClose}
      style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <DialogContent>
        <Typography style={{ fontWeight: "bold", textAlign: "center" }}>
          {t("Adjust permissions")}
        </Typography>
        <TableContainer style={{ padding: "0 10px 0 10px" }}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                  >
                    {t("Module")}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                  >
                    {t("You")}
                    <InfoIconWithTooltip
                      tooltipText={t("You have the following permissions regarding your colleague")}
                    />
                  </Typography>
                </TableCell>

                <TableCell align="right">
                  <Typography
                    style={{
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                  >
                    {t("Colleague")}{" "}
                    <InfoIconWithTooltip
                      tooltipText={t("Your colleague has the following permissions regarding you")}
                    />
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Group />
                  </Box>
                </TableCell>
                <TableCell align={"right"}>
                  <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Switch
                      data-testid={"colleague-card-permission-team-booking"}
                      onChange={handleTeamBookingColleagueChange}
                      checked={colleague.teamBookingReqColleague}
                    />
                  </Box>
                </TableCell>
                <TableCell align={"right"}>
                  <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Switch
                      data-testid={"colleague-card-permission-team-booking-colleague"}
                      onChange={handleTeamBookingPermissionChange}
                      checked={colleague.teamBookingPermission}
                    />
                  </Box>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Today />
                  </Box>
                </TableCell>
                <TableCell align={"right"}>
                  <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Switch
                      data-testid={"colleague-card-permission-schedule"}
                      onChange={handleScheduleColleagueChange}
                      checked={
                        isInquiry ? colleague.scheduleColleague : colleague.scheduleReqColleague
                      }
                    />
                  </Box>
                </TableCell>
                <TableCell align={"right"}>
                  <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Switch
                      data-testid={"colleague-card-permission-schedule-colleague"}
                      onChange={handleSchedulePermissionChange}
                      checked={colleague.schedulePermission}
                    />
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Grid style={{ padding: 10 }} container justifyContent={"center"}>
          <Button
            data-testid={"colleague-permissions-dialog-submit"}
            onClick={isInquiry ? handleSubmitInquiry : handleSubmit}
            color={"primary"}
          >
            {t("Accept")}
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
