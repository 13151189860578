import {
  BookingScheduleDto,
  BookingScheduleInterface
} from "../../../features/Booking-Form/typings/booking.types";
import { useRemoteQuery } from "../useRemote/useRemoteQuery";

// when !userInfo.pricingModels.includes(PricingModel.ENTERPRISE)
export function useRemoteFetchPlaceZoneSchedule(bookingScheduleDto: BookingScheduleDto) {
  const { userIds, startDate, endDate } = bookingScheduleDto;

  return useRemoteQuery<BookingScheduleInterface[]>({
    queryKey: ["placeZoneSchedule", bookingScheduleDto],
    url: "/booking-schedule/placezone",
    params: { userIds, startDate, endDate },
    enabled: !!startDate && !!endDate
  });
}
